/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  var lang = $('body').hasClass('lang-fr') ? 'fr' : 'en';

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // LoyalAction
    'is_privilege_program': {
      init: function() {
        // JavaScript to be fired on LoyalAction's page

        // Display progress bar
        UTIL.fire('is_privilege_program', 'progressBar');

        // Animated count
        UTIL.fire('is_privilege_program', 'valueCount');

        // Automated actions
        UTIL.fire('is_privilege_program', 'automatedActions');

        // Coupons
        UTIL.fire('is_privilege_program', 'coupons');
      },

      finalize: function() {
        // JavaScript to be fired after the init JS
      },

      progressBar: function() {
        // This event is called when the customer information is loaded.
        // Copied from LoyalAction's demo site and adapted to our code and to add anchors on the tier labels
        $(document).bind("dataLoaded.loyalaction", function(event, data) {
          // Only show the progress bar if the user is logged in and in a tiered program.
          if(!data.anonymous && data.tierEnabled) {
            // Setup tiers (e.g., base, bronze, silver, gold)
            var $progressBar = $(".loyalaction-progress-bar");
            var tiers_length = data.tiers.length;
            var step = 100.0 / (tiers_length - 1);

            var tier_position_property = "left";
            var indicator_dimension_property = "width";

            if($('body.mobile').length){
              tier_position_property = "bottom";
              indicator_dimension_property = "height";
            }

            // We need this for the "animate()" function to work using variables as properties
            var indicator_options = {};
            indicator_options[indicator_dimension_property] = data.tierCompletionPercentageFloat + "%";
            indicator_options[tier_position_property] = 0;

            var bubble_options = {};
            bubble_options[tier_position_property] = data.tierCompletionPercentageFloat + "%";

            // Custom per site, needed to link to proper description on page
            var tiers_anchors = ['bronze', 'silver', 'gold', 'elite'];

            // If inner elements have not already been added
            if(!$progressBar.find('.loyalaction-progress-bar-container').length) {
              // Add bar container and colored bar indicator
              $progressBar.append('<div class="loyalaction-progress-bar-container"><div class="loyalaction-progress-indicator"></div></div>');

              // Add bar bubble
              $progressBar.append('<div class="loyalaction-progress-bubble"></div>');
            }

            // Remove tier labels if they already exist
            $progressBar.find('.loyalaction-tiers').remove();

            // Add UL for tiers
            $progressBar.append('<ul class="loyalaction-tiers"></ul>');

            for(var i = 0; i < tiers_length; i++) {
              var $tierSeparator = $('<li><div><a href="#loyalty-' + tiers_anchors[i] + '">' + data.tiers[i].label + '</a></div></li>');

              if(i > 0 && i < (tiers_length - 1)) {
                $tierSeparator.css(tier_position_property, i * step + "%");
              }

              $progressBar.find('ul').append($tierSeparator);
            }

            // Show progress bar section
            $(".loyalaction-feat-tiers").show();

            // Set progress bar completion value
            $(".loyalaction-progress-indicator").animate(indicator_options, 2000);

            // Set bubble position
            $(".loyalaction-progress-bubble").animate(bubble_options, 2000);
          }
        });
      },

      valueCount: function() {

        $(document).on("dataLoaded.loyalaction", function(event, data) {

          $('.loyalaction-feat-stats .balance .loyalty-user-balance').countTo({
            from: 0,
            to: data.balanceFloat,
            speed: 1500,
            refreshInterval: 40,
            formatter: function (value, options) {
              if ($('body.lang-fr').length) {
                return value.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, " ");
              } else {
                return value.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
              }
            },
          });

          $('.loyalaction-feat-stats .money .counter').countTo({
            from: 0,
            to: data.balanceValueFloat,
            speed: 1500,
            refreshInterval: 40,
            formatter: function (value, options) {
              if ($('body.lang-fr').length) {
                return value.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,').replace(/\./g, ',') + ' $';
              } else {
                return '$' + value.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
              }

            },
          });

          $('.loyalaction-feat-stats .transactions .counter').countTo({
            from: 0,
            to: data.transactionsSize,
            speed: 1500,
            refreshInterval: 40,
          });
        });
      },

      automatedActions: function() {
        $(document).on("dataLoaded.loyalaction", function(event, data) {

          var completed_msg;
          var birthday_msg;

          if (lang == 'fr') {
            completed_msg = '<em>Merci!</em>';
            birthday_msg = 'Joyeux anniversaire!';
          } else {
            completed_msg = '<em>Thanks!</em>';
            birthday_msg = 'Happy birthday!';
          }

          $('.loyalaction-feat-actions li').each(function(){
            // Facebook like
            if ($(this).hasClass('facebook')) {
              if (data.actions.facebook_like && data.actions.facebook_like.facebook_like_enabled == true){
                // Show if action is enabled
                $(this).removeClass('hidden');

                // Add note text
                $(this).find('.note').text(data.actions.facebook_like.facebook_like_label);
              } else {
                // Remove if action is disabled
                $(this).remove();
              }

              if( data.actions.facebook_like ) {
                if (data.actions.facebook_like.facebook_like_completed == true) {
                  // Add "completed" class
                  $(this).addClass('completed');

                  // Add "Thanks!" message
                  $(this).find('.content').html(completed_msg);
                } else {
                  // Add LoyalAction class and url
                  $(this).find('a').addClass(data.actions.facebook_like.facebook_like_class);
                  $(this).find('a').attr('href', data.actions.facebook_like.facebook_like_post_url);

                  // Add points value
                  $(this).find('.value .number').text(data.actions.facebook_like.facebook_like_points_amount);
                }
              }
            }


            // Google review
            if ($(this).hasClass('googleplus')) {
              if (data.actions.google_review && data.actions.google_review.google_review_enabled == true){
                // Show if action is enabled
                $(this).removeClass('hidden');

                // Add note text
                $(this).find('.note').text(data.actions.google_review.google_review_label);
              } else {
                // Remove if action is disabled
                $(this).remove();
              }

              if ( data.actions.google_review ) {
                if (data.actions.google_review.google_review_completed == true) {
                  // Add "completed" class
                  $(this).addClass('completed');

                  // Add "Thanks!" message
                  $(this).find('.content').html(completed_msg);
                } else {
                  // Add LoyalAction class and url
                  $(this).find('a').addClass(data.actions.google_review.google_review_class);
                  $(this).find('a').attr('href', data.actions.google_review.google_review_url);

                  // Add points value
                  $(this).find('.value .number').text(data.actions.google_review.google_review_points_amount);
                }
              }
            }


            // Customer satisfation (transaction)
            if ($(this).hasClass('review')) {
              if (data.actions.customer_satisfaction && data.actions.customer_satisfaction.customer_satisfaction_enabled == true) {
                // Show if action is enabled
                $(this).removeClass('hidden');

                // Add note text
                $(this).find('.note').text(data.actions.customer_satisfaction.customer_satisfaction_label);
              } else {
                // Remove if action is disabled
                $(this).remove();
              }

              if( data.actions.customer_satisfaction ) {
                if (data.actions.customer_satisfaction.customer_satisfaction_show_disabled == true) {
                  // Add "completed" class
                  $(this).addClass('disabled');
                  $(this).addClass('nolink');

                  // Add "Thanks!" message
                  // $(this).find('.content').html(completed_msg);

                  // Add points value
                  $(this).find('.value .number').text(data.actions.customer_satisfaction.customer_satisfaction_points_amount);
                } else {
                  // Add LoyalAction class
                  $(this).find('a').addClass(data.actions.customer_satisfaction.customer_satisfaction_class);

                  // Add points value
                  $(this).find('.value .number').text(data.actions.customer_satisfaction.customer_satisfaction_points_amount);
                }

                if (data.actions.customer_satisfaction.customer_satisfaction_completed == true) {
                  // Add "completed" class
                  $(this).addClass('completed');

                  // Add "Thanks!" message
                  $(this).find('.content').html(completed_msg);
                }
              }
            }

            // Refer a friend
            if ($(this).hasClass('referral')) {
              if (data.actions.refer_friend && data.actions.refer_friend.refer_friend_enabled == true) {
                // Show if action is enabled
                $(this).removeClass('hidden');

                // Add note text
                $(this).find('.note').text(data.actions.refer_friend.refer_friend_label);
              } else {
                // Remove if action is disabled
                $(this).remove();
              }

              if( data.actions.refer_friend ) {
                if (data.actions.refer_friend.refer_friend_completed == true) {
                  // Add "completed" class
                  $(this).addClass('completed');

                  // Add "Thanks!" message
                  $(this).find('.content').html(completed_msg);
                } else {
                  // Add LoyalAction class and url
                  $(this).find('a').addClass(data.actions.refer_friend.refer_friend_class);
                  $(this).find('a').attr('href', data.actions.refer_friend.refer_friend_form_url);

                  // Add points value
                  $(this).find('.value .number').text(data.actions.refer_friend.refer_friend_points_amount);
                }
              }
            }

            // Birthday
            if ($(this).hasClass('birthday')) {
              if (data.actions.birthday && data.actions.birthday.birthday_enabled == true) {
                // Show if action is enabled
                $(this).removeClass('hidden');

                // Add note text
                $(this).find('.note').text(data.actions.birthday.birthday_label);

                // Add points value
                $(this).find('.value .number').text(data.actions.birthday.birthday_points_amount);
              } else {
                // Remove if action is disabled
                $(this).remove();
              }

              if (data.actions.birthday && data.actions.birthday.birthday_is_today == true) {
                $(this).find('.content').html(birthday_msg);
              }
            }

            // Trip Advisor
            if ($(this).hasClass('tripadvisor')) {
              if (data.actions.trip_advisor && data.actions.trip_advisor.trip_advisor_enabled == true) {
                // Show if action is enabled
                $(this).removeClass('hidden');

                // Add note text
                $(this).find('.note').text(data.actions.trip_advisor.trip_advisor_label);
              } else {
                // Remove if action is disabled
                $(this).remove();
              }

              if( data.actions.trip_advisor ) {
                if (data.actions.trip_advisor.trip_advisor_completed == true) {
                  // Add "completed" class
                  $(this).addClass('completed');

                  // Add "Thanks!" message
                  $(this).find('.content').html(completed_msg);
                } else {
                  // Add LoyalAction class and url
                  $(this).find('a').addClass(data.actions.trip_advisor.trip_advisor_class);
                  $(this).find('a').attr('href', data.actions.trip_advisor.trip_advisor_url);

                  // Add points value
                  $(this).find('.value .number').text(data.actions.trip_advisor.trip_advisor_points_amount);
                }
              }
            }

            // Confirm profile infos
            if ($(this).hasClass('profile')) {

              if (data.actions.confirmed_information && data.actions.confirmed_information.confirm_info_enabled == true) {
                // Show if action is enabled
                $(this).removeClass('hidden');

                // Add note text
                $(this).find('.note').text(data.actions.confirmed_information.confirm_info_label);
              } else {
                // Remove if action is disabled
                $(this).remove();
              }

              if( data.actions.confirmed_information ) {
                if (data.actions.confirmed_information.confirm_info_completed == true) {
                  // Add "completed" class
                  $(this).addClass('completed');

                  // Add "Thanks!" message
                  $(this).find('.content').html(completed_msg);
                } else {
                  // Add LoyalAction class and url
                  $(this).find('a').addClass(data.actions.confirmed_information.confirm_info_class);

                  // Add points value
                  $(this).find('.value .number').text(data.actions.confirmed_information.confirm_info_points_amount);
                }
              }
            }

            if ($(this).hasClass('mobile')) {
              if (data.actions.mobile_login && data.actions.mobile_login.mobile_login_enabled && data.actions.mobile_login.mobile_login_enabled == true) {
                // Show if action is enabled
                $(this).removeClass('hidden');

                // Add note text
                $(this).find('.note').text(data.actions.mobile_login.mobile_login_label);
              } else {
                // Remove if action is disabled
                $(this).remove();
              }

              if ( data.actions.mobile_login && data.actions.mobile_login.mobile_login_enabled ) {
                if (data.actions.mobile_login.mobile_login_completed == true) {
                  // Add "completed" class
                  $(this).addClass('completed');

                  // Add "Thanks!" message
                  $(this).find('.content').html(completed_msg);
                } else {
                  // Add LoyalAction class and url
                  $(this).find('a').addClass(data.actions.mobile_login.mobile_login_class);
                  // $(this).find('a').attr('href', data.actions.trip_advisor.trip_advisor_url);

                  // Add points value
                  $(this).find('.value .number').text(data.actions.confirmed_information.confirm_info_points_amount);
                }
              }
            }
          });

          // Disable click on "no link" actions
          $('.loyalaction-feat-actions .nolink').click(function() {
            return false;
          });

          // Disable click on "completed" actions
          $('.loyalaction-feat-actions .completed').click(function() {
            return false;
          });
        });
      },

      coupons: function() {
        // This event is called when the customer information is loaded.
        // Copied from LoyalAction's demo site and adapted to our code and to add anchors on the tier labels
        $(document).bind("dataLoaded.loyalaction", function(event, data) {

          if(data.anonymous || ! data.tierEnabled || ! data.benefitsEnabled || data.benefitItems.length === 0 ) {
            $(".loyalaction-feat-coupons").addClass('hidden');
            return;
          }

          var sHtmlListCoupons = '';
          var sCouponLabel = "Coupon";
          var sExpirationLabel = "exp.:";

          if (lang == 'fr') {
            sCouponLabel = "Coupon";
            sExpirationLabel = "exp.&nbsp;:";
          }

          $('.loyalaction-feat-coupons').append('<ul></ul>');

          for( var i in data.benefitItems )
          {
            var oDataCoupon = data.benefitItems[i];

            var sHtmlExpiration = '';
            var sCouponLink     = '';
            var sCouponTitle    = '';

            sCouponLink = oDataCoupon.url;
            sCouponTitle = oDataCoupon.label;
            if( oDataCoupon.expiration )
            {
              sHtmlExpiration = ''+
                '<span class="expiration">' +
                  '<span class="label">' + sExpirationLabel + '</span> ' +
                  '<span class="date">' + oDataCoupon.expiration + '</span>' +
                '</span>';
            }

            sHtmlListCoupons += ''+
              '<li>' +
                '<a href="'+sCouponLink+'" target="_blank">' +
                  '<span class="box">' +
                    '<span class="img"></span>' +
                    '<span class="content">' +
                      '<em>' + sCouponLabel + '</em>' +
                      sHtmlExpiration +
                    '</span>' +
                  '</span>' +
                  '<span class="note">'+sCouponTitle+'</span>' +
                '</a>' +
              '</li>';

            delete oDataCoupon;
          } // end foreach


          $(".loyalaction-feat-coupons").removeClass('hidden');
          $(".loyalaction-feat-coupons > ul").append(sHtmlListCoupons);
        });
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
